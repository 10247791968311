<template>
  <v-container
    class="primary"
    fluid
  >
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      color="blue-grey lighten-5"
      right
      class="elevation-0"
      width="860"
      temporary
    />

    <v-card :loading="loading">
      <v-toolbar
        dark
        dense
        color="blue"
      >
        <v-col cols="1">
          <v-avatar
            tile
            dense
            size="55"
            color="blue lighten-2"
            class="avatar rounded-lg"
          >
            <v-icon
              color="white"
              x-large
            >
              mdi-account-group
            </v-icon>
          </v-avatar>
        </v-col>
        <h3 class="ml-3">
          Citas
        </h3>
        <v-spacer />
        <v-col cols="3">
          <v-text-field
            v-model="search"
            class="mx-2"
            solo-inverted
            dense
            color="blue"
            append-icon="mdi-magnify"
            label="Filtrar"
            single-line
            hide-details
          />
        </v-col>
        <v-btn
          small
          class="ma-4"
          color="light-blue lighten-1"
          @click="consultar"
        >
          Consultar <v-icon>mdi-update</v-icon>
        </v-btn>
        <v-btn
          align="right"
          color="orange"
          dark
          small
          class="ma-2"
          @click.stop="nuevaCita"
        >
          Nuevo <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>

    <v-row>
      <v-col
        cols="8"
        class="mt-3"
      >
        <v-card elevation="10">
          <v-data-table
            hide-default-footer
            :loading="loading"
            :headers="headers"
            :items="miscitas"
            :search="search"
          >
            <template v-slot:item="{ item }">
              <tr @dblclick.stop.prevent="editar(item)">
                <!-- <td class="red--text">
                  {{ item.idcita }} / {{ item.lugar }}
                </td> -->
                <td>{{ item.nomcont }}/ {{ item.empresa }}</td>
                <td>{{ item.asunto }}</td>
                <td class="text-xs-right">
                  {{ item.temas }}
                </td>
                <td>{{ item.fechacorta }} {{ item.hora }} </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="4">
        <!-- <v-card height="150">
        <v-img
          contain
          src="@/assets/cita1.png"
          id="imagen"
          class="imagen ml-14"
        >
        </v-img>
        </v-card> -->
        <miscitas />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// import cita from '@/views/crm/citas/cita'
import miscitas from '@/views/crm/citas/miscitas'
export default {
  components: {
    miscitas
  },
  data() {
    return {
      drawer: false,
      loading: false,
      search: '',
      miscitas: [],

      // FILTROS
      idusariosweb: 0,
      idcontacto: 0,
      idproyecto: 0,
      fecha1: new Date(Date.now() - 30 - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),

      fecha2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),

      headers: [
        // {
        //   text: 'Id',
        //   value: 'idcita',
        //   align: 'end',
        //   color: 'red',
        //   sortable: false
        // },
        { text: 'Contacto/ Empresa', align: 'end', value: 'idcontacto' },
        // { text: 'Empresa', value: 'empresa' },
        { text: 'Cita', value: 'titulo', width: '30%'},
        { text: 'Asunto', value: 'temas' , width: '30%'},
        { text: 'Fecha Hora / lugar', value: 'fechastart' },
        // { text: 'Hora', value: 'hora' },
        // { text: 'Lugar', value: 'lugar' }
      ],
      json_fields: {
        Id: 'idcita',
        Nombre: 'idcontacto',
        Cliente: 'cliente',
        Asunto: 'temas',
        Fecha: 'fechastart',
        Hora: 'hora',
        Lugar: 'lugar'
      },
      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8'
          }
        ]
      ],
      json_data: []
    }
  },

  computed: {
    ...mapGetters('contactos', ['getContacto', 'getNewContacto']),
    ...mapGetters('clientes', ['getCliente']),
    ...mapGetters('login', ['getdatosUsuario'])
  },

  created() {
    // Definir valores iniciales
    // Fecha inicial Primero de mes.
    let date0 = new Date()
    let mes = date0.getMonth()
    let anio = date0.getFullYear()

    // console.log(mes, anio)
    this.fecha1 = new Date(anio, mes, 1).toISOString().substr(0, 10)
    this.idusuariosweb = parseInt(this.getdatosUsuario.idusuariosweb)
    this.consultar()
  },

  methods: {
    ...mapActions('oportunidad', ['setOportunidad']),
    consultar() {
      // limpiar
      this.miscitas = []
      // console.log(this.getdatosUsuario)
      // let id = this.getdatosUsuario.idusuariosweb
      // console.log('consultar', id)

      this.loading = true
      let payload = {
        fechastart: this.fecha1,
        fechaend: this.fecha2,
        idusuariosweb: this.idusuariosweb,
        idproyecto: this.idproyecto,
        idcontacto: this.idcontacto
      }

      console.log('payloadfiltros ', payload)
      // this.$http.get("v2/citas.list" ).then(response=>{
      this.$http
        .post('v2/con.citas', payload)
        .then(response => {
          if (response.data.error === null) {
            this.loading = false
            this.miscitas = response.data.result

            this.miscitas.forEach(element => {
              element.hora = element.fechastart.substr(11, 17)
              element.fechastart = element.fechastart.substr(0, 10)
              let cFecha = new Date(element.fechastart).toLocaleDateString(
                'es-MX',
                {
                  timeZone: 'UTC',
                  month: 'short',
                  day: 'numeric'
                }
              )
              element.fechacorta = cFecha
            })

            console.log('citas', this.miscitas)
          }
        })
        .catch(error => {
          console.log(error)
        })
    },

    editar(item) {
      console.log('Renglonitem', item)
      this.$router
        .push({ name: 'cita', params: { cita: item } })
        .catch(err => {})
    },

    nuevaCita() {
      // console.log('oportunidad seleccionada', opor1)
      // Mandar llamar al Action.
      // this.setOportunidad(opor1)
      this.$router.push({ name: 'cita' })

      // this.drawer = !this.drawer
    }
  }
}
</script>

<style scoped>
.avatar {
  top: -10px;
}
.imagen {
  height: 200px;
  width: 200px;
}
</style>

<template>
  <v-row>
    <v-col>
      <v-card
        height="485"
        class="rounded-lg mt-2"
        color="blue-grey lighten-5"
      >
        <v-toolbar
          dense
          dark
          class="rounded-lg"
          primary-title
          color="blue lighten-1"
        >
          <v-row justify="center">
            <v-toolbar-title
              id="titulo"
              class="titulo"
            >
              MI DIA
            </v-toolbar-title>
          </v-row>
          <!-- <v-btn
            dark
            text
            :to="{name: 'cita'}"
          >
            Nueva
            <v-icon>mdi-pencil</v-icon>
          </v-btn> -->
        </v-toolbar>
        <v-divider />

        <v-list
          two-line
          class="overflow-y-auto mx-1 mt-1"
        >
          <v-list-item-group
            v-model="selected"
            active-class="primary--text"
          >
            <template v-for="(item, index) in miscitas">
              <v-list-item :key="item.idcitas">
                <template v-slot:default="{ active }">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.asunto" />

                    <v-list-item-subtitle
                      class="text--primary"
                      v-text="item.headline"
                    />

                    <v-list-item-subtitle v-text="item.fecha" />
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-list-item-action-text v-text="item.hora" />

                    <v-icon
                      v-if="!active"
                      color="grey lighten-1"
                    >
                      mdi-star-outline
                    </v-icon>

                    <v-icon
                      v-else
                      color="yellow darken-3"
                    >
                      mdi-star
                    </v-icon>
                  </v-list-item-action>
                </template>
              </v-list-item>

              <v-divider
                v-if="index < items.length - 1"
                :key="index"
              />
            </template>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    selected: [2],
    items: [],
    miscitas: [],
    fecha: new Date().toISOString().substr(0, 10),
  }),

  created(){
    this.consultar()
  },

  methods: {
      
    consultar(){
      // limpiar
      this.miscitas= []
      // console.log(this.getdatosUsuario)
      // let id = this.getdatosUsuario.idusuariosweb
      // console.log('consultar', id)

      let payload = {
        fechastart : this.fecha
      }
      // console.log("payload", payload)
      this.$http.post("v2/miscitas", payload ).then(response=>{
        console.log("respon mis citas", response)
        if (response.data.error === null && response.data.result.length > 0) {
          this.miscitas = response.data.result
          // console.log(this.miscitas)

          this.miscitas.forEach( element => {
            element.fecha = element.fechastart.substr(5,5)
            element.hora = element.fechastart.substr(11,17)
          })

          // console.log("citas", this.miscitas)
        }
          
      }).catch(error=>{
        console.log(error)
      })
    },
      
    ver(item){
      console.log("orden", item.idorden)
      this.verOrden(item.idordenes)
    },
  }
}
</script>

//